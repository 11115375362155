import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import FooterMenu from '../../components/FooterMenu';
import api from '../../services/api';
import { logout } from '../../services/auth';
import Nav from '../../components/Nav';
import PageHead from '../../components/PageHead';
import {getToken} from '../../services/auth';
import Lead from '../../components/Lead';

export default function SearchPage(){
    const [leads,setLeads]=useState([]);
    const history=useHistory();
    const [isLoading,setIsLoading]=useState(true);
    const [search,setSearch]=useState('');
    const [isSearching,setIsSearching]=useState(false);
    useEffect(()=>{
        carregarLeads();
        return ()=>{//This code runs when component is unmounted
            setLeads([]);
        }
    },[]);
    function carregarLeads(){
        (async function(){
            try{
                const response=await api.post('/leads/search',{
                    search,
                    token: 'Bearer '+getToken(),
                });
                if(typeof response.data.leads!='undefined'){
                    setLeads(response.data.leads);
                    setIsLoading(false);
                    setIsSearching(false);
                    return;
                }
                if(typeof response.data.error!='undefined'){
                    logout();
                    history.push('/');
                    setIsLoading(false);
                    return;
                }
            }catch(error){
                console.log(error);
                setIsLoading(false);
                history.push('/');
            }
        })();
    }
    function handleFilter(event){
        event.preventDefault();
        setIsSearching(true);
        carregarLeads();
    }
    return (
        <>
            <Nav showBackButton={false} />

            <div className="content-area">

                <PageHead title="Procurar" />

                <div className="container over">
                    <div className="section">
                        <div className="input-field col s12">
                            <input type="search" name="search" id="search" onChange={e=>setSearch(e.target.value)} />
                            <label htmlFor="search">Procurar leads</label>
                        </div>
                        <p> </p>
                        <a href="/#" onClick={handleFilter} className="waves-effect waves-teal btn">
                            {isSearching&&(<div className="preloader-wrapper small active" style={{width: '16px',height: '16px',marginRight: '6px'}}>
                                <div className="spinner-layer spinner-yellow-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>)}
                            Procurar
                        </a>
                    </div>
                    <div className="section">
                        {leads.length>0?leads.map(e=><Lead lead={e} key={`search-lead-${e.id}`} />):null}

                        {leads.length===0&&!isLoading&&<div className="center" style={{padding: '40px 0'}}>
                            A lista está vazia
                        </div>}

                        {isLoading&&(
                            <div className="center" style={{paddingTop: '30px',paddingBottom: '30px'}}>
                                <div className="preloader-wrapper small active">
                                    <div className="spinner-layer spinner-yellow-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <FooterMenu />
        </>
    );
}
