export default function PageHead(props){
    return (
        <>
            <div className="pagehead-bg primary-bg">
            </div>

            <div className="container has-pagehead is-pagetitle">
                <div className="section">
                    <h5 className="pagetitle">{props.title}</h5>
                </div>
            </div>
        </>
    );
}