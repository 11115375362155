import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import FooterMenu from '../../components/FooterMenu';
import Nav from '../../components/Nav';
import PageHead from '../../components/PageHead';
import api from '../../services/api';
import {logout,getToken} from '../../services/auth';

export default function ProfilePage(){
    const [usuario,setUsuario]=useState(null);
    const [senha,setSenha]=useState('');
    const [confirmaSenha,setConfirmaSenha]=useState('');
    const [isLoading,setIsLoading]=useState(false);
    const history=useHistory();
    useEffect(()=>{
        (async function(){
            try{
                const response=await api.post(`/profile`,{
                    token: 'Bearer '+getToken(),
                });
                if(typeof response.data.usuario!=='undefined'){
                    setUsuario(response.data.usuario);
                }
            }catch(error){
                console.log(error);
            }
        })();
    },[]);
    async function handleSaveSenha(event){
        event.preventDefault();
        if(!senha||!confirmaSenha){
            window.M.toast({
                html: 'Informe a nova senha',
            },4000);
            return;
        }
        if(senha!==confirmaSenha){
            window.M.toast({
                html: 'As senhas não coincidem',
            },4000);
            return;
        }
        document.querySelector('#pass').disabled=true;
        document.querySelector('#confirmPass').disabled=true;
        setIsLoading(true);
        try{
            // const response=
            await api.post(`/profile/pass`,{
                senha,
                token: 'Bearer '+getToken(),
            });
            setIsLoading(false);
            document.querySelector('#pass').disabled=false;
            document.querySelector('#confirmPass').disabled=false;
            document.querySelector('#pass').value='';
            document.querySelector('#confirmPass').value='';
            window.M.toast({
                html: 'Senha alterada',
            },4000);
        }catch(error){
            console.log(error);
            setIsLoading(false);
            document.querySelector('#pass').disabled=false;
            document.querySelector('#confirmPass').disabled=false;
        }
    }
    function handleLogout(event){
        event.preventDefault();
        logout();
        history.push('/');
    }
    return (
        <>
            <Nav showBackButton={false} />

            <div className="content-area">

                <PageHead title="Perfil" />

                <div className="container over">
                    <div className="section">
                        <p>Olá <strong>{usuario?usuario.nome:null}</strong>! <a href="/#" onClick={handleLogout}>Sair</a></p>
                        <h4>Trocar senha</h4>
                        <div className="input-field col s12">
                            <input type="password" name="pass" id="pass" onChange={e=>setSenha(e.target.value)} />
                            <label htmlFor="pass">Nova senha</label>
                        </div>
                        <div className="input-field col s12">
                            <input type="password" name="confirmPass" id="confirmPass" onChange={e=>setConfirmaSenha(e.target.value)} />
                            <label htmlFor="confirmPass">Confirmar senha</label>
                        </div>
                        <a href="/#" className="waves-effect waves-teal btn" onClick={handleSaveSenha} style={{marginTop: '20px'}}>
                            {isLoading&&(<div className="preloader-wrapper small active" style={{width: '16px',height: '16px',marginRight: '6px'}}>
                                <div className="spinner-layer spinner-yellow-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>)}
                            Salvar
                        </a>
                    </div>
                </div>
            </div>
            <FooterMenu />
        </>
    );
}
