import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FooterMenu from '../../components/FooterMenu';
import Nav from '../../components/Nav';
import PageHead from '../../components/PageHead';
import api from '../../services/api';
import { DateTime } from "luxon";
import {getToken} from '../../services/auth';

export default function LeadPage(){
    const {id}=useParams();
    const [lead,setLead]=useState(null);
    const [status,setStatus]=useState('2');
    const [leadStatus,setLeadStatus]=useState('');
    const [description,setDescription]=useState('');
    const [isLoading,setIsLoading]=useState(false);
    const [historico,setHistorico]=useState([]);
    const [leadCurrentStatusId,setLeadCurrentStatusId]=useState(0);
    useEffect(()=>{
        (async function(){
            // var instance=
            window.M.Tabs.init(document.querySelector('#tab-lead'));
            try{
                const response=await api.post(`/leads/lead/${id}`,{
                    token: 'Bearer '+getToken(),
                });
                if(typeof response.data.lead!=='undefined'){
                    setLead(response.data.lead);
                    setLeadStatus(response.data.lead.status);
                    setLeadCurrentStatusId(response.data.lead.status_id);
                }
                if(typeof response.data.historico!=='undefined'){
                    setHistorico(response.data.historico);
                }
            }catch(error){
                console.log(error);
            }
        })();
    },[]);
    async function handleSaveStatus(event){
        event.preventDefault();
        if(!description||!status){
            window.M.toast({
                html: 'Informe os dados corretamente',
            },4000);
            return;
        }
        document.querySelector('#desc').disabled=true;
        document.querySelector('#status').disabled=true;
        setIsLoading(true);
        try{
            const response=await api.post(`/leads/status/${id}`,{
                status,
                description,
                status_id_antigo: leadCurrentStatusId,
                token: 'Bearer '+getToken(),
            });
            if(typeof response.data.historico!=='undefined'){
                setHistorico(response.data.historico);
                setLeadStatus(response.data.historico[0].status_novo);
                setLeadCurrentStatusId(response.data.historico[0].status_id_novo);
            }
            setIsLoading(false);
            document.querySelector('#desc').disabled=false;
            document.querySelector('#status').disabled=false;
            document.querySelector('#desc').value='';
            document.querySelector('#status').value='2';
            setStatus('2');
            setDescription('');
        }catch(error){
            console.log(error);
            setIsLoading(false);
            document.querySelector('#desc').disabled=false;
            document.querySelector('#status').disabled=false;
        }
    }
    return (
        <>
            <Nav showBackButton={true} />

            <div className="content-area">

                <PageHead title="Leads" />

                <div className="container over">
                    <div className="section">
                        <div className="spacer"></div>
                        <div className="row mb0">
                            <div className="col s12 pad-0">
                                <div className="row ">
                                    <div className="col s12" style={{marginBottom: '15px'}}>
                                        <ul className="tabs" id="tab-lead">
                                            <li className="tab col s3">
                                                <a className="" href="#tab-174714718-0">Informações</a>
                                            </li>
                                            <li className="tab col s3">
                                                <a className="" href="#tab-174714718-1">Status</a>
                                            </li>
                                            <li className="tab col s3">
                                                <a className="" href="#tab-174714718-2">Histórico</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="tab-174714718-0" className="col s12">
                                        <div className="tab-content">
                                            <p>
                                                <small>Nome</small>
                                                <br />
                                                <strong>{lead?lead.nome:''}</strong>
                                            </p>
                                            {lead&&lead.email&&<p>
                                                <small>e-mail</small>
                                                <br />
                                                <strong>{lead.email}</strong>
                                            </p>}
                                            <p>
                                                <small>Origem</small>
                                                <br />
                                                <strong>{lead?lead.origem:''}</strong>
                                            </p>
                                            {lead&&lead.url_origem&&<p>
                                                <small>URL de origem</small>
                                                <br />
                                                <a rel="noreferrer" href={lead.url_origem} target="_blank" className="blue-text">
                                                    {lead.url_origem.replace(/^(https{0,1}:\/\/)(\S{20})(\S*)(\S{30})$/gm,'$2...$4')}
                                                    <br />
                                                </a>
                                                <a href={lead.url_origem} className="waves-effect waves-light btn">Abrir URL</a>
                                            </p>}
                                            {lead&&lead.telefone&&<p>
                                                <small>Telefone</small>
                                                <br />
                                                <strong>{lead?lead.telefone:''}</strong>
                                            </p>}
                                            {lead&&lead.telefone&&<p>
                                                <a rel="noreferrer" href={`https://wa.me/55${lead.telefone.replace(/\D/g,'')}`} target="_blank" className="waves-effect waves-teal btn">
                                                    Chamar no WhatsApp
                                                </a>
                                            </p>}
                                            <p>
                                                <small>Mensagem</small>
                                                <br />
                                                {lead?lead.mensagem:''}
                                            </p>
                                            <p>
                                                <small>Status</small>
                                                <br />
                                                <strong>{leadStatus?leadStatus:null}</strong>
                                            </p>
                                            {lead&&lead.atualizador_nome&&<p>
                                                <small>Atualizado por</small>
                                                <br />
                                                <strong>{lead?lead.atualizador_nome:null}</strong>
                                            </p>}
                                            <p>
                                                <small>Tipo</small>
                                                <br />
                                                <strong>{lead?(lead.tipo==='V'?'Venda':'Locação'):null}</strong>
                                            </p>
                                            <p>
                                                <small>Criado em</small>
                                                <br />
                                                {lead?DateTime.fromSQL(lead.create_time_real).setLocale('pt').toFormat('dd LLL yyyy. H:mm'):null}
                                            </p>
                                            {lead&&lead.update_time&&<p>
                                                <small>Data de atualização</small>
                                                <br />
                                                {lead?DateTime.fromSQL(lead.update_time).setLocale('pt').toFormat('dd LLL yyyy. H:mm'):null}
                                            </p>}
                                        </div>
                                    </div>
                                    <div id="tab-174714718-1" className="col s12">
                                        <div className="tab-content">
                                            <div className="row">
                                                <div className="col s12">
                                                    <p>Status atual: {leadStatus}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s12">
                                                    <label htmlFor="status">Novo status</label>
                                                    <select name="status" id="status" className="browser-default" onChange={e=>setStatus(e.target.value)}>
                                                        <option value="2">Não contactado</option>
                                                        <option value="3">Contato negativo</option>
                                                        <option value="4">Contato positivo</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="input-field col s12">
                                                    <textarea name="desc" id="desc" rows="6" className="materialize-textarea" onChange={e=>setDescription(e.target.value)}></textarea>
                                                    <label htmlFor="desc">Descrição</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s12 center">
                                                    <a href="/#" className="waves-effect waves-teal btn" onClick={handleSaveStatus}>
                                                        {isLoading&&(<div className="preloader-wrapper small active" style={{width: '16px',height: '16px',marginRight: '6px'}}>
                                                            <div className="spinner-layer spinner-yellow-only">
                                                                <div className="circle-clipper left">
                                                                    <div className="circle"></div>
                                                                </div>
                                                                <div className="gap-patch">
                                                                    <div className="circle"></div>
                                                                </div>
                                                                <div className="circle-clipper right">
                                                                    <div className="circle"></div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                        Salvar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-174714718-2" className="col s12">
                                        <div className="tab-content">
                                            {historico.length?(historico.map(e=>(
                                                <blockquote className="" key={`s${e.id}`}>
                                                    <div className="row" key={`h${e.id}`}>
                                                        <div className="col s7 blue-text">
                                                            {e.usuario_nome}
                                                        </div>
                                                        <div className="col s5 text-right">
                                                            <small style={{fontSize: '0.80rem'}}>{lead?DateTime.fromSQL(e.create_time).setLocale('pt').toFormat('dd LLL yyyy. H:mm'):null}</small>
                                                        </div>
                                                    </div>
                                                    {e.descricao&&(
                                                        <div className="row">
                                                            <div className="col s12">
                                                                {e.descricao}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <s>{e.status_antigo}</s> {'>'} <span className={`${e.status_id_novo===4?'green':(e.status_id_antigo===3?'blue':'red')}-text`}>{e.status_novo}</span>
                                                        </div>
                                                    </div>
                                                </blockquote>
                                            ))):(
                                                <div className="center" style={{paddingTop: '20px'}}>
                                                    O status ainda não foi alterado
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterMenu />
        </>
    );
}
