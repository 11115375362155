import React from 'react';
import { useHistory } from 'react-router';
import { DateTime } from "luxon";

export default function Lead(props){
    const history=useHistory();
    function handleLeadClick(id){
        history.push(`/lead/${id}`);
    }
    return (
        <div className="card z-depth-2" onClick={()=>handleLeadClick(props.lead.id)}>
            <div className="card-content" style={{padding: '12px'}}>
                <span className="new badge">{props.lead.tipo==='V'?'Venda':'Locação'}</span>
                <span className="card-title" style={{marginBottom: 0}}>{props.lead.nome}</span>
                <small style={{fontSize: '0.94rem'}}>
                    {DateTime.fromSQL(props.lead.create_time_real).setLocale('pt').toFormat('dd LLL yyyy. H:mm')}
                </small>
            </div>
            <div className="card-action custom-bg center white-text" style={{padding: '12px'}}>
                {props.lead.status}
                {/* <span className="new badge blue">{e.status}</span> */}
            </div>
        </div>
    );
}
