import React, { useEffect } from 'react';
import FooterMenu from '../../components/FooterMenu';
import Nav from '../../components/Nav';
import PageHead from '../../components/PageHead';
import api from '../../services/api';
import {getToken} from '../../services/auth';
import { useState } from 'react/cjs/react.development';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';


export default function PlantaoPage(){
    const [events,setEvents]=useState([]);
    useEffect(function(){
        (async function(){
            try{
                const response=await api.post(`/profile/plantao`,{
                    token: 'Bearer '+getToken(),
                });
                setEvents(response.data);
            }catch(error){
                console.log(error);
            }
        })();
    },[]);
    return (
        <>
            <Nav showBackButton={false} />

            <div className="content-area">

                <PageHead title="Plantão" />

                <div className="container over">
                    <div className="section">
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        events={events}
                    />
                    </div>
                </div>
            </div>
            <FooterMenu />
        </>
    );
}
