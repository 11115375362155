import React, { useEffect } from 'react';
import FooterMenu from '../../components/FooterMenu';
import Nav from '../../components/Nav';
import PageHead from '../../components/PageHead';
import api from '../../services/api';
import {getToken} from '../../services/auth';
import { useState } from 'react/cjs/react.development';

export default function PlantaoNovoPage(){
    const [events,setEvents]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
    let lastDate='';
    useEffect(function(){
        (async function(){
            try{
                const response=await api.post(`/profile/plantao`,{
                    token: 'Bearer '+getToken(),
                });
                setEvents(response.data);
                setIsLoading(false);
            }catch(error){
                console.log(error);
            }
        })();
    },[]);
    return (
        <>
            <Nav showBackButton={false} />

            <div className="content-area">

                <PageHead title="Plantão" />

                <div className="container over">
                    <div className="section">
                        {events.length>0?events.map(e=>{
                            const dateTime=new Date(e.inicio);
                            const dateTimeFim=new Date(e.fim);
                            const newDate=dateTime.getDate().toString().padStart(2,'0')+'/'+(dateTime.getMonth()+1).toString().padStart(2,'0')+'/'+dateTime.getFullYear().toString().padStart(2,'0');
                            let showDate=false;
                            if(lastDate!==newDate){
                                lastDate=newDate;
                                showDate=true;
                            }
                            return (
                                <div key={e.id}>
                                    {showDate&&<h4>{newDate}</h4>}
                                    {`Das ${dateTime.getHours().toString().padStart(2,'0')}:${dateTime.getMinutes().toString().padStart(2,'0')}`} às {`${dateTimeFim.getHours().toString().padStart(2,'0')}:${dateTimeFim.getMinutes().toString().padStart(2,'0')}`}
                                </div>
                            );
                        }):null}
                        {!isLoading&&events.length===0?<p style={{marginBottom:'80px',textAlign:'center'}}>A lista está vazia</p>:null}
                        {isLoading&&(
                            <div className="center" style={{paddingTop: '30px',paddingBottom: '30px'}}>
                                <div className="preloader-wrapper small active">
                                    <div className="spinner-layer spinner-yellow-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <FooterMenu />
        </>
    );
}
