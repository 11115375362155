import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "../services/auth";
import LoginPage from '../pages/login';
import LeadsPage from "../pages/leads";
import LeadPage from "../pages/leads/lead";
import ProfilePage from "../pages/profile";
import SearchPage from "../pages/leads/search";
import PlantaoPage from "../pages/plantao";
import PlantaoNovoPage from "../pages/plantaonovo";
import RegisterPage from "../pages/login/register";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route path="/register" component={RegisterPage} />
      {/* <Route path="/signup" component={() => <h1>SignUp</h1>} /> */}
      <PrivateRoute path="/leads" component={LeadsPage} />
      <PrivateRoute path="/lead/:id" component={LeadPage} />
      <PrivateRoute path="/profile" component={ProfilePage} />
      <PrivateRoute path="/search" component={SearchPage} />
      <PrivateRoute path="/plantao" component={PlantaoPage} />
      <PrivateRoute path="/plantaonovo" component={PlantaoNovoPage} />
      <Route path="*" component={()=><h1>Erro</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
