import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import agora from '../images/agora.png';

export default function Nav(props){
    const history=useHistory();

    useEffect(()=>{
        const theme=localStorage.getItem('agora-theme');
        document.querySelector('body').setAttribute('data-site_mode',theme?theme:'light');
        document.querySelector('body').setAttribute('data-header',theme?theme:'light');
        document.querySelector('body').setAttribute('data-menu',theme?theme:'light');
        document.querySelector('body').setAttribute('data-footer',theme?theme:'light');
        document.querySelector('body').setAttribute('data-footer_menu_style',theme?theme:'light');
    },[]);
    function handleChangeTheme(event){
        event.preventDefault();
        const theme=localStorage.getItem('agora-theme');
        document.querySelector('body').setAttribute('data-site_mode',theme==='dark'?'light':'dark');
        document.querySelector('body').setAttribute('data-header',theme==='dark'?'light':'dark');
        document.querySelector('body').setAttribute('data-menu',theme==='dark'?'light':'dark');
        document.querySelector('body').setAttribute('data-footer',theme==='dark'?'light':'dark');
        document.querySelector('body').setAttribute('data-footer_menu_style',theme==='dark'?'light':'dark');
        localStorage.setItem('agora-theme',theme==='dark'?'light':'dark');
        localStorage.setItem('menu',theme==='dark'?'light':'dark');
        localStorage.setItem('footer_menu_style',theme==='dark'?'light':'dark');
        localStorage.setItem('header',theme==='dark'?'light':'dark');
        localStorage.setItem('footer',theme==='dark'?'light':'dark');
    }
    function handleGoBack(){
        history.goBack();
    }
    return (
        <nav className="fix_topscroll logo_on_fixed topbar navigation">
            <div className="nav-wrapper container">
                <a id="logo-container" href="/#" className="brand-logo">
                    <img src={agora} alt="Ágora" style={{width: '100px'}} />
                </a>
                {props.showBackButton&&(
                    <a href="/#" className="waves-effect waves-circle navicon back-button htmlmode show-on-large" onClick={handleGoBack}>
                        <i className="mdi mdi-chevron-left"></i>
                    </a>
                )}
                <a href="/#" className="waves-effect waves-circle navicon right sidenav-trigger show-on-large" onClick={handleChangeTheme}>
                    <i className="app-icon-brightness-contrast"></i>
                </a>
            </div>
        </nav>
    );
}
