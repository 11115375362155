import React from 'react';
import { useHistory } from 'react-router';

export default function FooterMenu(){
    const history=useHistory();
    function handleClickLeads(event){
        event.preventDefault();
        history.push('/leads');
    }
    function handleClickProfile(event){
        event.preventDefault();
        history.push('/profile');
    }
    function handleClickProcurar(event){
        event.preventDefault();
        history.push('/search');
    }
    function handleClickPlantao(event){
        event.preventDefault();
        history.push('/plantaonovo');
    }
    return (
        <div className="footer-menu circular">
            <ul style={{display: 'flex',justifyContent: 'space-around'}}>
                <li style={{justifyContent: 'center'}} onClick={handleClickLeads}>
                    <a href="/#">
                        <i className="mdi mdi-star-outline"></i>
                        <span>Leads</span>
                    </a>
                </li>
                <li style={{justifyContent: 'center'}} onClick={handleClickProcurar}>
                    <a href="/#">
                        <i className="mdi mdi-magnify"></i>
                        <span>Procurar</span>
                    </a>
                </li>
                {/* <li style={{justifyContent: 'center'}} onClick={handleClickPlantao}>
                    <a href="/#">
                        <i className="mdi mdi-calendar"></i>
                        <span>Plantão</span>
                    </a>
                </li> */}
                <li style={{justifyContent: 'center'}} onClick={handleClickProfile}>
                    <a href="/#">
                        <i className="mdi mdi-account-outline"></i>
                        <span>Perfil</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}
