import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import FooterMenu from '../../components/FooterMenu';
import api from '../../services/api';
import { logout } from '../../services/auth';
import Nav from '../../components/Nav';
import PageHead from '../../components/PageHead';
import {getToken} from '../../services/auth';
import Lead from '../../components/Lead';

export default function LeadsPage(){
    const [leads,setLeads]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
    const history=useHistory();
    // let interval=null;
    const time=1000*60*5;//5 minutos
    useEffect(()=>{
        loadLeads();
        // interval=
        setInterval(()=>{
            loadLeads();
        },time);
        return ()=>{//This code runs when component is unmounted
            setLeads([]);
        }
    },[]);
    async function loadLeads(){
        try{
            const response=await api.post('/leads',{
                token: 'Bearer '+getToken(),
            });
            if(typeof response.data.leads!='undefined'){
                setLeads(response.data.leads);
                setIsLoading(false);
                return;
            }
            if(typeof response.data.error!='undefined'){
                logout();
                history.push('/');
                setIsLoading(false);
                return;
            }
        }catch(error){
            console.log(error);
            setIsLoading(false);
            history.push('/');
        }
    }
    return (
        <>
            <Nav showBackButton={false} />

            <div className="content-area">

                <PageHead title="Leads" />

                <div className="container over">
                    <div className="section">
                        {leads.length>0?leads.map(e=><Lead lead={e} key={`lead-${e.id}`} />):null}

                        {leads.length===0&&!isLoading&&<div className="center" style={{padding: '40px 0'}}>
                            A lista está vazia
                        </div>}

                        {isLoading&&(
                            <div className="center" style={{paddingTop: '30px',paddingBottom: '30px'}}>
                                <div className="preloader-wrapper small active">
                                    <div className="spinner-layer spinner-yellow-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <FooterMenu />
        </>
    );
}
