import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { login, isAuthenticated, getToken } from "../../services/auth";
import { useHistory } from "react-router";
import agora from '../../images/agora.png';
import {useLocation} from "react-router-dom";

export default function LoginPage(){
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    // const [hasError,setError]=useState(false);
    const [isLoading,setLoading]=useState(false);
    const history=useHistory();

    function useQuery(){
        const {search}=useLocation();
        return React.useMemo(()=>new URLSearchParams(search),[search]);
    }

    let query=useQuery();

    useEffect(()=>{
        if(isAuthenticated()){
            const osid=query.get('osid');
            if(osid){
                api.post('/auth/update_device_id',{
                    token: 'Bearer '+getToken(),
                    deviceId: osid,
                });
            }else{
                console.log('Id do dispositivo não informado');
            }

            history.push('/leads');
            return;
        }
    });

    function handleRegisterClick(event){
        event.preventDefault();
        history.push('/register');
    }

    async function handleSubmit(event){
        event.preventDefault();
        if(!email||!password){
            // setError('Informe corretamente os dados de login');
            window.M.toast({
                html: 'Informe corretamente os dados de login',
            },4000);
            return;
        }
        // setError(false);
        setLoading(true);
        document.querySelector('#email').disabled=true;
        document.querySelector('#pass').disabled=true;
        const osid=document.querySelector('#osid').value;
        try{
            const response=await api.post("/auth",{
                email,
                password,
                deviceId: osid,
            });
            if(typeof response.data.token!='undefined'&&response.data.token){
                login(response.data.token);
                setLoading(false);
                history.push('/leads');
            }
            document.querySelector('#email').disabled=false;
            document.querySelector('#pass').disabled=false;
            // setError(response.data.error);
            window.M.toast({
                html: response.data.error,
            },4000);
            setLoading(false);
        }catch(error){
            // document.querySelector('#email').disabled=false;
            // document.querySelector('#pass').disabled=false;
            // setError('Erro ao iniciar sessão');
            setLoading(false);
        }
    }
    if(!isAuthenticated()){
        return (
            <div className="content-area">
                <form action="" method="post" onSubmit={handleSubmit}>
                    <div className="login-bg access-login"></div>
                    <div className="container login-area">
                        <div className="section">
                            <div className="center" style={{marginBottom: '20px'}}>
                                <img src={agora} alt="Ágora" />
                            </div>
                            {/* <h3 className="bot-20 center white-text">Login</h3> */}
                            <div className="row">
                                <div className="input-field col s10 offset-s1">
                                    <input type="email" className="validate" id="email" onChange={e=>setEmail(e.target.value)} style={{boxShadow: 'none'}} />
                                    <label htmlFor="email">E-mail</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s10 offset-s1">
                                    <input type="password" id="pass" className="validate" onChange={e=>setPassword(e.target.value)} style={{boxShadow: 'none'}} />
                                    <label htmlFor="pass">Senha</label>
                                    <input type="hidden" value={query.get('osid')||''} id="osid" />
                                </div>
                            </div>
                            <div className="row center">
                                <button type="submit" className="waves-effect waves-light btn-large bg-primary">
                                    {isLoading&&(<div className="preloader-wrapper small active" style={{width: '16px',height: '16px',marginRight: '6px'}}>
                                        <div className="spinner-layer spinner-yellow-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>)}
                                    Entrar
                                </button>
                                <div className="spacer"></div>
                                <div className="links">
                                    {/* <a href="ui-pages-forgotpassword.html">Forgot Password</a>
                                    <span className="sep">|</span> */}
                                    <a href="#" onClick={handleRegisterClick}>Register</a>
                                </div>
                            </div>
                            {/* <div className="row center">
                                {hasError&&(
                                    <div>{hasError}</div>
                                )}
                            </div> */}
                        </div>
                    </div>
                </form>
            </div>
        );
    }else return '';
}
